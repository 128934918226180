import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
} from 'react';

type NavigationContextType = {
  history: string[];
};

const NavigationContext = createContext<NavigationContextType | undefined>(
  undefined
);

type NavigationProviderProps = {
  children: ReactNode;
};

export const NavigationProvider: React.FC<NavigationProviderProps> = ({
  children,
}) => {
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    // Ensure we only run in the browser
    if (typeof window === 'undefined') return undefined;

    setHistory([window.location.pathname]);

    const handleNavigation = () => {
      setHistory((prevHistory) => [...prevHistory, window.location.pathname]);
    };

    // Listen for popstate (back/forward buttons)
    window.addEventListener('popstate', handleNavigation);

    // Override pushState & replaceState to detect programmatic navigation
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = (...args) => {
      originalPushState.apply(window.history, args);
      handleNavigation();
    };

    window.history.replaceState = (...args) => {
      originalReplaceState.apply(window.history, args);
      handleNavigation();
    };

    return () => {
      window.removeEventListener('popstate', handleNavigation);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  return (
    <NavigationContext.Provider value={{history}}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = (): NavigationContextType => {
  if (typeof window === undefined) return {history: []};

  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
